<template>
  <div class="flex h-full flex-col justify-between">
    <div v-if="fromServiceData">
      <div>
        <div
          class="mt-[21px] flex flex-col flex-wrap justify-between px-4 sm:px-0 md:flex-row md:items-end"
        >
          <h1 class="title mr-[15px]">
            Thanks for<br />
            providing this data!
          </h1>
        </div>
        <p class="subscribe_text payment_message mt-[30px] px-4 sm:px-0">
          {{ paymentMessage }}
        </p>
        <SummaryInfo
          classnames="summary_title_button_wrapper dark:bg-[#272729] mt-[47px] md:mt-[56px] pb-[44px] border-b-[1px] border-placeholder"
          title="Service Data - Completed"
          disabled="true"
          prepare="serviceData"
        />
        <SummaryInfo
          classnames="summary_title_button_wrapper pb-0 rounded-b-[40px] border-placeholder form_to_edit dark:bg-[#333639] mt-[-38px]"
          title="Contact Data - Completed"
          disabled="false"
          prepare="contactData"
        />
      </div>
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            action="https://my.sendinblue.com/users/subscribeembed/js_id/30qw4/id/1"
            method="POST"
            class="flex w-full items-center justify-start gap-3 max-sm:flex-col"
          >
            <input type="hidden" name="js_id" id="js_id" value="30qw4" />
            <input type="hidden" name="from_url" id="from_url" value="yes" />
            <input type="hidden" name="hdn_email_txt" id="hdn_email_txt" value="" />
            <input type="hidden" name="sib_simple" value="simple" />
            <input type="hidden" name="sib_forward_url" :value="projectLink" id="sib_forward_url" />
            <input
              type="text"
              class="input subscribe_input dark:!border-[#878787]"
              placeholder="E-mail Address"
              name="email"
              id="email"
              required
            />
            <button type="submit" class="subscribe_button" data-editfield="subscribe">
              Subscribe
            </button>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="fromStayWithUs">
      <div class="mt-[21px] flex flex-col flex-wrap justify-between md:flex-row md:items-end">
        <h1 class="title mr-[15px]">Thanks for<br />your Request!</h1>
        <p class="subtitle">
          If you have any Questions Feel<br />Free to Send us
          <a class="text-main" :href="'mailto:' + email">Email</a> or
          <a class="text-main" :href="'tel:' + phone">Call us</a>
        </p>
      </div>
      <p class="subscribe_text payment_message mt-[30px]">{{ paymentMessage }}</p>
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            action="https://my.sendinblue.com/users/subscribeembed/js_id/30qw4/id/1"
            method="POST"
            class="flex w-full items-center justify-start gap-3 max-sm:flex-col"
          >
            <input
              type="text"
              class="input subscribe_input dark:!border-[#878787]"
              placeholder="E-mail Address"
              name="email"
              id="email"
              required
            />
            <button type="submit" class="subscribe_button" data-editfield="subscribe">
              Subscribe
            </button>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-[21px] flex flex-col flex-wrap justify-between md:flex-row md:items-end">
        <h1 class="title mr-[15px]">Thanks for<br />your Request!</h1>
        <p class="subtitle">
          If you have any Questions Feel<br />Free to Send us
          <a class="text-main" :href="'mailto:' + email">Email</a> or
          <a class="text-main" :href="'tel:' + phone">Call us</a>
        </p>
      </div>
      <p class="subscribe_text payment_message mt-[30px]">{{ paymentMessage }}</p>
      <SummaryInfo
        classnames="summary_title_button_wrapper pb-6 border-b-[1px] border-placeholder"
        title="Service Data"
        disabled="true"
        prepare="serviceData"
      />
      <SummaryInfo
        classnames="bg-white shadow-none relative z-20 -mt-8 px-4 py-6 dark:bg-[#333639] sm:p-8 rounded-[40px]"
        title="Account Data"
        disabled="false"
        prepare="contactData"
      />
      <div class="mt-4 grid gap-4 md:mt-8 lg:grid-cols-2 lg:gap-8">
        <div
          class="flex flex-col items-start justify-between gap-y-6 rounded-[40px] bg-[#DDE5DC] px-6 py-8 dark:bg-[#272729]"
        >
          <p class="subscribe_text">
            Please, Subscribe for your Newsletters to be Informed about our News and Promotions
          </p>
          <form
            action="https://my.sendinblue.com/users/subscribeembed/js_id/30qw4/id/1"
            method="POST"
            class="flex w-full items-center justify-start gap-3 max-sm:flex-col"
          >
            <input
              type="text"
              class="input subscribe_input dark:!border-[#878787]"
              placeholder="E-mail Address"
              name="email"
              id="email"
              required
            />
            <button type="submit" class="subscribe_button" data-editfield="subscribe">
              Subscribe
            </button>
          </form>
        </div>
        <div
          class="bg-main flex flex-grow flex-col items-start justify-between gap-y-6 rounded-[40px] px-6 py-8 sm:p-8"
        >
          <div class="space-y-3">
            <span class="text-background text-2xl/[110%] font-bold">New Service</span>
            <p class="text-background text-base/[110%] sm:text-[18px]/[138%]">
              If you wish to book other services please click here.
            </p>
          </div>
          <button
            class="bg-background text-main w-full rounded-[37px] py-[22px] text-[18px]/[110%] font-semibold"
          >
            New Service
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SummaryInfo from '@/components/SummaryInfo.vue'
import { onMounted, onBeforeMount, onBeforeUnmount, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useContactsStore } from '@/stores/contacts'

import { useOrderStore } from '@/stores/order'
import { useProfileCompletionStore } from '@/stores/profile_completion'
import { storeToRefs } from 'pinia'

const route = useRoute()

const projectLink = ref(import.meta.env.VITE_PROJECT_URL)
const phone = ref(import.meta.env.VITE_PROJECT_PHONE)
const email = ref(import.meta.env.VITE_PROJECT_EMAIL)
const profileCompletionStore = useProfileCompletionStore()
const orderStore = useOrderStore()
const contactsStore = useContactsStore()

const { profile, fromServiceData } = storeToRefs(profileCompletionStore)
const { orderData, fromStayWithUs, tour } = storeToRefs(orderStore)

let paymentMessage = computed(() => {
  const messageRequest =
    'We are currently receiving a very high volume of requests and answering you may take longer than normal.'
  const messageValue = `Dear ${orderData.value.first_name} ${orderData.value.last_name},
                        thank you very much, we have received your payment of Euro ${orderData.value.total}.
                        We will now process the booking and arrange the service, when this is done you will receive a confirmation email.`
  return route.query.redirect_status || profile.value.first_name !== ''
    ? messageValue
    : messageRequest
})

onMounted(() => {})

onBeforeMount(async () => {
  if (tour) {
    contactsStore.add(orderData.value)
  }
})

onBeforeUnmount(() => {
  orderStore.$reset()
  profileCompletionStore.$reset()
})
</script>
